import { Box, Grid, Skeleton, Typography } from "@mui/material"
import { debounce } from "lodash"
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { MainLayout, SnackbarWithText, Topbar } from "../../../components"
import { FeedbackFAB } from "../../../components/buttons/feedbackFAB"
import { DeletePopup } from "../../../components/ui/deletePopup"
import { EmptyCard } from "../../../components/video/"
import ResponsiveVideoPlayerModal from "../../../components/video/ResponsiveVideoPlayerModal"
import { VideosList } from "../../../components/video/videosList"
import { useAppDispatch, useAppSelector } from "../../../redux"
import { AppActions } from "../../../redux/appSlice"
import { NonPersistedActions } from "../../../redux/nonPersistedSlice"
import {
  deleteVideo,
  getAnalyticsProperties,
  listVideos,
  logAnalyticsEvent,
} from "../../../services"
import { listenForVideo } from "../../../services/db"
import {
  AnalyticsEvents,
  AnalyticsProducts,
  ApiState,
  Colors,
  FeedbackEntity,
  initialSnackbarProps,
  isTypeofVideo,
  ModuleName,
  PageMode,
  Screen,
  Video,
  VideoScreen,
} from "../../../types"

const videosPerPage = 6

export function TextToVideoScreen() {
  const [snackbarProps, setSnackbarProps] = useState(initialSnackbarProps)
  const [isLoading, setIsLoading] = useState(false)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const [videoToDelete, setVideoToDelete] = useState<Video | null>(null)
  const [videoToPreview, setVideoToPreview] = useState<Video | null>(null)
  const [allVideos, setAllVideos] = useState<Video[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [totalVideos, setTotalVideos] = useState<number | null>(null)
  const [searchParams] = useSearchParams()
  const showSubscriptions = searchParams.get("showSubscriptions") === "true"

  const showSubscriptionsModalOnAppLoad = useAppSelector(
    (state) => state.nonPersisted.showSubscriptionsModalOnAppLoad,
  )

  useEffect(() => {
    if (showSubscriptions || showSubscriptionsModalOnAppLoad) {
      setTimeout(() => {
        dispatch(AppActions.updateShowStripeModal(true))
        dispatch(NonPersistedActions.resetShowSubscriptionsModalOnAppLoad())
      }, 2000)
    }
  }, [showSubscriptions, showSubscriptionsModalOnAppLoad])

  const containerRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.app.user)
  const entitiesWithFeedback = useAppSelector(
    (state) => state.app.entitiesWithFeedback,
  )

  const fetchVideos = useCallback(
    async (isInitialFetch = false) => {
      if ((!hasMore || isFetching) && !isInitialFetch) return

      setIsFetching(true)
      try {
        const lastId = isInitialFetch ? "" : allVideos[allVideos.length - 1]?.id
        const response = await listVideos({
          last_id: lastId || "",
          limit: videosPerPage,
        })
        const fetchedVideos = response.filter((e) => e.campaignId == null)
        setAllVideos((prevVideos) =>
          isInitialFetch ? fetchedVideos : [...prevVideos, ...fetchedVideos],
        )
        setHasMore(fetchedVideos.length === videosPerPage)

        const processingVideos = fetchedVideos.filter(
          (e) => e.state === ApiState.processing,
        )
        processingVideos.forEach((video) => {
          listenForVideo(video.id, user).then((updatedVideo) => {
            setAllVideos((prevVideos) =>
              prevVideos.map((prevVideo) =>
                prevVideo.id === updatedVideo.id ? updatedVideo : prevVideo,
              ),
            )
          })
        })

        if (isInitialFetch) {
          setIsInitialLoading(false)
        }
      } catch (error) {
        console.error("Error fetching videos:", error)
        setSnackbarProps({ open: true, text: "Error loading videos" })
      } finally {
        setIsFetching(false)
      }
    },
    [hasMore, isFetching, allVideos, user],
  )

  useEffect(() => {
    fetchVideos(true)
  }, [])

  const handleScroll = useCallback(
    debounce(() => {
      if (!containerRef.current || isFetching || !hasMore) return

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      if (scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetchVideos(false)
      }
    }, 200),
    [fetchVideos, isFetching, hasMore],
  )

  useEffect(() => {
    const currentContainer = containerRef.current
    if (currentContainer) {
      currentContainer.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  const handlePreviewVideo = (item: Video) => {
    if (isTypeofVideo(item)) {
      setVideoToPreview(item)
    }
  }

  const createNewVideo = async () => {
    logAnalyticsEvent(
      AnalyticsEvents.ttv_started,
      getAnalyticsProperties(AnalyticsProducts.text_to_video),
    )
    dispatch(AppActions.updatePageMode(PageMode.CREATE))
    dispatch(AppActions.updateOpenedModule(ModuleName.videos))
    navigate(`/${Screen.videos}/${VideoScreen.selectVideoAvatar}`)
  }

  const deleteSelectedVideo = async () => {
    if (!videoToDelete) return

    setIsLoading(true)
    try {
      await deleteVideo(videoToDelete)
      setAllVideos((prevVideos) =>
        prevVideos.filter((video) => video.id !== videoToDelete.id),
      )
      toast.success("Video deleted successfully")
    } catch (e) {
      console.error("Error deleting video:", e)
      setSnackbarProps({ open: true, text: "Error deleting video" })
    } finally {
      setIsLoading(false)
      setDeletePopupOpen(false)
      setVideoToDelete(null)
    }
  }

  const cancelDeletion = () => {
    setVideoToDelete(null)
    setDeletePopupOpen(false)
  }

  const SkeletonLoader = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
        margin: 5,
      }}
    >
      {[...Array(6)].map((_, index) => (
        <Box
          key={index}
          sx={{
            width: "22rem",
            padding: 2,
            backgroundColor: Colors.white,
            borderRadius: 4,
            boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height="15rem"
            sx={{ borderRadius: "0.5rem" }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "0.5rem",
            }}
          >
            <Skeleton variant="text" width="40%" height={24} />
            <Skeleton variant="text" width="30%" height={20} />
          </Box>
        </Box>
      ))}
    </Box>
  )

  const handleEdit = async (video: Video) => {
    dispatch(AppActions.updateSelectedVideo(video))
    dispatch(AppActions.updatePageMode(PageMode.UPDATE))
    dispatch(AppActions.updateOpenedModule(ModuleName.videos))
    navigate(`/${Screen.videos}/${VideoScreen.selectVideoAvatar}`)
  }

  const handleDelete = (video: Video) => {
    setVideoToDelete(video)
    setDeletePopupOpen(true)
  }

  const handleOpenFeedbackModal = (video: Video) => {
    if (!video.id || entitiesWithFeedback.has(FeedbackEntity.VIDEO)) return

    dispatch(
      AppActions.updateFeedbackModalState({
        visibility: "full",
        entityId: video.id,
        entityType: FeedbackEntity.VIDEO,
      }),
    )
  }

  const handleClosePreview = () => {
    const video = videoToPreview
    setVideoToPreview(null)
    handleOpenFeedbackModal(video)
  }

  const checkAndLoadMoreVideos = useCallback(() => {
    if (!containerRef.current || isFetching || !hasMore) return

    const { scrollHeight, clientHeight } = containerRef.current
    if (scrollHeight <= clientHeight) {
      fetchVideos(false)
    }
  }, [fetchVideos, isFetching, hasMore])

  useEffect(() => {
    if (!isInitialLoading) {
      checkAndLoadMoreVideos()
    }
  }, [isInitialLoading, checkAndLoadMoreVideos])

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      checkAndLoadMoreVideos()
    }, 200)

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [checkAndLoadMoreVideos])

  return (
    <MainLayout>
      <div style={{ height: "80vh", display: "flex", flexDirection: "column" }}>
        <SnackbarWithText props={snackbarProps} />
        <Topbar title="Videos" cta={"New Video +"} onClick={createNewVideo} />
        {isInitialLoading || isLoading ? (
          <div style={{ padding: 16 }}>
            <SkeletonLoader />
          </div>
        ) : allVideos.length === 0 ? (
          <Box sx={{ padding: 2, overflow: "auto" }}>
            <Typography
              style={{
                color: Colors.purple900,
                fontSize: "1.5rem",
                textAlign: "center",
              }}
              variant="body1"
            >
              Welcome to Beyond Presence! Let's get started -
            </Typography>
            <Typography
              style={{
                color: Colors.purple900,
                fontSize: "1.5rem",
                textAlign: "center",
                marginBottom: "2rem",
              }}
              variant="body1"
            >
              Create your first video
            </Typography>
            <Grid container justifyContent="center">
              <Grid
                display="flex"
                justifyContent="center"
                item
                xs={12}
                sm={6}
                md={4}
              >
                <EmptyCard onClick={createNewVideo} mainText="New Video" />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            ref={containerRef}
            sx={{ flexGrow: 1, overflow: "auto", padding: 2 }}
          >
            <VideosList
              videos={allVideos}
              onDeleteVideo={handleDelete}
              onEditVideo={handleEdit}
              onPreviewVideo={handlePreviewVideo}
            />
            {isFetching && <SkeletonLoader />}
          </Box>
        )}
      </div>
      <DeletePopup
        open={deletePopupOpen}
        onConfirm={deleteSelectedVideo}
        onCancel={cancelDeletion}
        text={`Delete video "${videoToDelete?.name}"?`}
      />
      <ResponsiveVideoPlayerModal
        videoUrl={videoToPreview?.url}
        previewUrl={videoToPreview?.previewUrl}
        isOpen={!!videoToPreview}
        onClose={handleClosePreview}
      />
      <FeedbackFAB />
    </MainLayout>
  )
}
