import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NonPersistedSlice } from "../types"

const initialState: NonPersistedSlice = {
  showSubscriptionsModalOnAppLoad: false,
  allConversationsTableState: {
    page: 0,
    rowsPerPage: 5,
    searchTerm: "",
  },
}

const nonPersistedSlice = createSlice({
  name: "nonPersisted",
  initialState,
  reducers: {
    updateShowSubscriptionsModalOnAppLoad: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showSubscriptionsModalOnAppLoad = action.payload
    },
    resetShowSubscriptionsModalOnAppLoad: (state) => {
      state.showSubscriptionsModalOnAppLoad = false
    },
    updateAllConversationsTableState: (
      state,
      action: PayloadAction<NonPersistedSlice["allConversationsTableState"]>,
    ) => {
      state.allConversationsTableState = action.payload
    },
    resetState: () => initialState,
  },
})

export const NonPersistedActions = nonPersistedSlice.actions
export default nonPersistedSlice.reducer
