import { Box, Stack } from "@mui/material"
import React, { useCallback } from "react"
import { Logo, SidebarHeadline, SidebarText } from "../../../components"
import { useAppDispatch, useAppSelector } from "../../../redux"
import { AppActions } from "../../../redux/appSlice"
import { getStripePortalUrl } from "../../../services/api"
import { Screen, SidebarTextValues } from "../../../types"
import { isDev } from "../../../utils"
import { CreditsCalculation } from "../../payment/creditsCalculation"

export function Sidebar() {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.app.user)

  const handleSettingsClick = useCallback(async () => {
    try {
      const stripePortalUrl = await getStripePortalUrl(
        user.uid,
        window.location.href,
      )
      window.open(stripePortalUrl, "_self")
    } catch (error) {
      console.error("Failed to get Stripe portal URL:", error)
    }
  }, [])

  const handleOpenHelpUsImproveModal = () => {
    dispatch(
      AppActions.updateFeedbackModalState({
        visibility: "full",
        entityId: null,
        entityType: null,
      }),
    )
  }

  const handleFaqClick = () => {
    window.open("https://www.beyondpresence.ai/#faq", "_blank")
  }

  return (
    <Stack
      height={"100%"}
      direction={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Box sx={{ mb: "2rem" }}>
          <Logo />
        </Box>
        <Box sx={{ mb: "2rem" }}>
          <Stack>
            <SidebarText
              text={SidebarTextValues.avatars}
              href={`/${Screen.avatars}`}
            />
          </Stack>
        </Box>
        {isDev && (
          <Box sx={{ mb: "2rem" }}>
            <Stack direction="column" spacing={"0.5rem"}>
              <SidebarHeadline text="Videos" />
              <SidebarText text={SidebarTextValues.textToVideo} href="/" />
              <SidebarText
                text="Outreach Videos"
                href={`/${Screen.outreachCampaigns}`}
              />
            </Stack>
          </Box>
        )}
        <Box sx={{ mb: "2rem" }}>
          <Stack direction="column" spacing={"0.5rem"}>
            <SidebarHeadline text="Conversational Agents" />
            <SidebarText
              text={SidebarTextValues.myAgents}
              href={`/${Screen.myAgents}`}
            />
            <SidebarText
              text={SidebarTextValues.knowledgeBase}
              href={`/${Screen.knowledgeBase}`}
            />
            <SidebarText
              text={SidebarTextValues.conversations}
              href={`/${Screen.conversations}`}
            />
          </Stack>
        </Box>
      </Box>
      <>
        <CreditsCalculation />
        <SidebarText
          text={SidebarTextValues.settings}
          href="#"
          onClick={handleSettingsClick}
        />
        <SidebarText
          text={SidebarTextValues.faq}
          href="#"
          onClick={handleFaqClick}
        />
        <SidebarText
          text={SidebarTextValues.helpUsImprove}
          href={`#`}
          onClick={handleOpenHelpUsImproveModal}
        />
      </>
    </Stack>
  )
}
