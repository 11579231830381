import {
  IconButton,
  styled,
  SxProps,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material"
import { Info } from "@phosphor-icons/react"
import React from "react"
import { Colors } from "../../types"

interface InfoTooltipProps {
  text: string
  placement?: TooltipProps["placement"]
  customStyle?: SxProps<Theme>
}

const ThemedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Colors.almostBlack,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.almostBlack,
    fontSize: 12,
    maxWidth: 200,
  },
}))

export default function InfoTooltip({
  text,
  placement = "top",
  customStyle,
}: InfoTooltipProps) {
  return (
    <ThemedTooltip title={text} placement={placement} sx={{ ...customStyle }}>
      <IconButton sx={{ padding: "0.2rem" }}>
        <Info size={16} color={Colors.purple800} />
      </IconButton>
    </ThemedTooltip>
  )
}
