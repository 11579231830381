import { Close } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { Smiley, SmileyMeh, SmileySad, SmileyWink } from "@phosphor-icons/react"
import * as Sentry from "@sentry/react"
import React, { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { feedbackTitles, feedbackTopics } from "../../constants"
import { useAppDispatch, useAppSelector } from "../../redux"
import { AppActions } from "../../redux/appSlice"
import { createFeedback } from "../../services"
import { Colors, Feedback, FeedbackEntity } from "../../types"
import { MainButton } from "../buttons"

interface FeedbackModalProps {}

export function FeedbackModal({}: FeedbackModalProps) {
  const [rating, setRating] = useState<number | null>(null)
  const [feedback, setFeedback] = useState<string>("")
  const [contactConsent, setContactConsent] = useState<boolean>(false)
  const [feedbackTopic, setFeedbackTopic] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const dispatch = useAppDispatch()

  const modalState = useAppSelector((state) => state.app.feedbackModalState)
  const user = useAppSelector((state) => state.app.user)

  const selectedFeedbackTopics = useMemo(() => {
    if (modalState?.entityType === FeedbackEntity.AVATAR) {
      return feedbackTopics.avatarFeedbackTopics
    } else if (modalState?.entityType === FeedbackEntity.AGENT) {
      return feedbackTopics.agentFeedbackTopics
    } else {
      return feedbackTopics.appFeedbackTopics
    }
  }, [modalState?.entityType])

  const selectedFeedbackTitle = useMemo(() => {
    if (modalState?.entityType === FeedbackEntity.AVATAR) {
      return feedbackTitles.avatarFeedbackTitle
    } else if (modalState?.entityType === FeedbackEntity.AGENT) {
      return feedbackTitles.agentFeedbackTitle
    } else {
      return feedbackTitles.appFeedbackTitle
    }
  }, [modalState?.entityType])

  const handleClose = () => {
    dispatch(
      AppActions.updateFeedbackModalState({
        visibility: "hidden",
        entityId: null,
        entityType: null,
      }),
    )
  }

  const handleSubmitFeedback = async () => {
    try {
      setError(null)
      setIsSubmitting(true)
      const payload: Partial<Feedback> = {
        contactConsent,
        feedback,
        rating,
        feedbackTopic,
        creatorId: user.uid,
        entityId: modalState?.entityId,
        entityType: modalState?.entityType,
      }
      await createFeedback(payload)
      toast.success("Thank you for your feedback!")
    } catch (error) {
      console.warn("Error submitting feedback", error)
      Sentry.captureException(error)
      setError("Something went wrong. Please try again later.")
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClearFeedback = () => {
    setFeedback("")
    setRating(0)
    setContactConsent(false)
  }

  const handleSubmit = async () => {
    if (!rating) {
      setError("Please rate the product")
      return
    }
    await handleSubmitFeedback()
    handleClearFeedback()
    handleClose()
  }

  const handlePressChip = (feedbackTopic: string) => {
    setFeedbackTopic(feedbackTopic)
  }

  if (!modalState || modalState?.visibility === "hidden") return null

  return (
    <Box sx={styles.modalOverlay}>
      <Box sx={styles.modalContent}>
        <IconButton onClick={handleClose} sx={styles.closeButton}>
          <Close />
        </IconButton>
        <Typography variant="h6" sx={styles.modalTitle}>
          {selectedFeedbackTitle}
        </Typography>
        {error && (
          <Typography variant="body2" color={Colors.red500}>
            {error}
          </Typography>
        )}
        <Box sx={styles.feedbackOptions}>
          <FeedbackOption
            Icon={SmileySad}
            text="Not what I expected"
            onClick={() => setRating(1)}
            selected={rating === 1}
          />
          <FeedbackOption
            Icon={SmileyMeh}
            text="Fine, but not perfect"
            onClick={() => setRating(2)}
            selected={rating === 2}
          />
          <FeedbackOption
            Icon={Smiley}
            text="Meets my expectation"
            onClick={() => setRating(3)}
            selected={rating === 3}
          />
          <FeedbackOption
            Icon={SmileyWink}
            text="Happy with the result!"
            onClick={() => setRating(4)}
            selected={rating === 4}
          />
        </Box>
        {modalState?.visibility === "full" && (
          <>
            <Box sx={styles.feedbackForm}>
              <Typography variant="subtitle1" sx={styles.feedbackPrompt}>
                Please help us improve! What can be done better?
              </Typography>
              <Box sx={styles.chipContainer}>
                {selectedFeedbackTopics.map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    onClick={() => handlePressChip(item)}
                    sx={[
                      styles.chip,
                      feedbackTopic === item && styles.chipSelected,
                    ]}
                    variant={feedbackTopic === item ? "outlined" : "filled"}
                  />
                ))}
              </Box>
              <TextField
                multiline
                rows={4}
                placeholder="Your comment"
                fullWidth
                variant="outlined"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                sx={styles.textField}
              />
            </Box>
            <Box sx={styles.checkboxContainer}>
              <Checkbox
                checked={contactConsent}
                onChange={(e) => setContactConsent(e.target.checked)}
              />
              <Typography variant="body2">
                You can contact me about this feedback
              </Typography>
            </Box>
          </>
        )}

        <Box sx={styles.submitButtonContainer}>
          <MainButton
            text="Submit Feedback"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  )
}

type FeedbackOptionProps = {
  Icon: React.ElementType
  text: string
  onClick: () => void
  selected: boolean
}

const FeedbackOption = ({
  Icon,
  text,
  onClick,
  selected,
}: FeedbackOptionProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Box
      sx={{
        width: "23%",
        aspectRatio: "1/1",
        borderRadius: "1rem",
        backgroundColor: selected ? Colors.purple200 : "#F5F5F7",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem",
        cursor: "pointer",
        border:
          isHovered || selected ? `2px solid ${Colors.purple900}` : "none",
      }}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Icon
        size={"1rem"}
        style={{ width: "4rem", height: "4rem", color: "#6301fe" }}
      />
      <Typography
        variant="body2"
        sx={{
          color: Colors.almostBlack,
          fontWeight: "bold",
          mt: "0.5rem",
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const styles = {
  modalOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(78, 64, 90, 0.40)",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "1rem",
    padding: "2rem",
    position: "relative",
    width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
    maxWidth: "600px",
    backgroundColor: "background.paper",
  },
  closeButton: {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
  },
  modalTitle: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
  },
  feedbackOptions: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1rem 0",
  },
  feedbackForm: {
    width: "100%",
  },
  feedbackPrompt: {
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
    marginBottom: "1rem",
  },
  chip: {
    backgroundColor: "action.hover",
    "&:hover": {
      backgroundColor: "action.selected",
    },
  },
  chipSelected: {
    backgroundColor: Colors.purple200,
  },
  textField: {
    marginBottom: "1rem",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}
